import {Suspense, lazy} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '../../_rsfoils/partials'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
// import EmployeeMasterPage from '../modules/employee/EmployeeMasterPage'

export function PrivateRoutes() {
  // =========================== Master =======================================
  const DepartmentMasterPage = lazy(
    () => import('../modules/master-page/department-master-page/DepartmentMasterPage')
  )
  const UserMasterPage = lazy(
    () => import('../modules/master-page/user-master-page/UserMasterPage')
  )
  const MachineMasterPage = lazy(
    () => import('../modules/master-page/machine-master-page/MachineMasterPage')
  )
  const PartMasterPage = lazy(
    () => import('../modules/master-page/part-master-page/PartMasterPage')
  )
  const ItemMasterMasterPage = lazy(
    () => import('../modules/master-page/item-master-page/ItemMasterMasterPage')
  )
  const PartSpecificationMasterPage = lazy(
    () =>
      import('../modules/master-page/part-specification-master-page/PartSpecificationMasterPage')
  )
  const ProcessBOMMasterPage = lazy(
    () => import('../modules/master-page/process-bom-master-page/ProcessBOMMasterPage')
  )
  const ProductionBOMMasterPage = lazy(
    () => import('../modules/master-page/production-bom-master-page/ProductionBOMMasterPage')
  )

  const LocationMasterPage = lazy(
    () => import('../modules/master-page/location-master-page/LocationMasterPage')
  )
  const ReturnReasonRemarkMaster = lazy(
    () => import('../modules/master-page/return-reason-remark/ReturnReasonRemarkMaster')
  )

  const CoreTypeMasterPage = lazy(
    () => import('../modules/master-page/core-type-master-page/CoreTypeMasterPage')
  )

  const RollDiameterMasterPage = lazy(
    () => import('../modules/master-page/roll-diameter-master-page/RollDiameterMasterPage')
  )

  const FoilTypeMasterPage = lazy(
    () => import('../modules/master-page/foil-type-master-page/FoilTypeMasterPage')
  )
  const ItemGroupMasterPage = lazy(
    () => import('../modules/master-page/item-group-master-page/ItemGroupMasterPage')
  )

  const MachineGroupMasterPage = lazy(
    () => import('../modules/master-page/machine-group-master-page/MachineGroupMasterPage')
  )

  const PartSubTypeMasterPage = lazy(
    () => import('../modules/master-page/part-sub-type-master-page/PartSubTypeMasterPage')
  )

  const SubstrateMasterPage = lazy(
    () => import('../modules/master-page/substrate-master-page/SubstrateMasterPage')
  )

  const TemperMasterPage = lazy(
    () => import('../modules/master-page/temper-master-page/TemperMasterPage')
  )

  const TypePackingMasterPage = lazy(
    () => import('../modules/master-page/type-packing-master-page/TypePackingMasterPage')
  )

  // ========================== Configuration ======================================
  const SAPConfigMasterPage = lazy(
    () =>
      import('../modules/configuration-master-pages/sap-config-master-pages/SAPConfigMasterPage')
  )
  const EmailConfigMasterPage = lazy(
    () =>
      import(
        '../modules/configuration-master-pages/email-config-master-pages/EmailConfigMasterPage'
      )
  )
  const PlantConfigMasterPage = lazy(
    () =>
      import(
        '../modules/configuration-master-pages/plant-config-master-pages/PlantConfigMasterPage'
      )
  )
  const ProcessConfigMasterPage = lazy(
    () =>
      import(
        '../modules/configuration-master-pages/process-config-master-pages/ProcessConfigMasterPage'
      )
  )
  const ProcessCheckConfigMasterPage = lazy(
    () =>
      import(
        '../modules/configuration-master-pages/preocess-check-config-master-pages/ProcessCheckConfigMasterPage'
      )
  )
  // ========================== Password Change ======================================
  const ChangePasswordPage = lazy(() => import('../modules/change-password/ChangePasswordPage'))
  // ========================== Machine Daily Check Change ======================================
  const MachineDailyCheckMasterPage = lazy(
    () => import('../modules/machine-daily-check-master-page/MachineDailyCheckMasterPage')
  )
  // ========================== Machine Preventive Change ======================================
  const MachinePreventiveMasterPage = lazy(
    () => import('../modules/machine-preventive-master-page/MachinePreventiveMasterPage')
  )
  const MachinePartChangeReqMasterPage = lazy(
    () => import('../modules/machine-part-change-req-master-page/MachinePartChangeReqMasterPage')
  )
  const CylinderChangeReqMasterPage = lazy(
    () => import('../modules/cylinder-change-req-master-page/CylinderChangeReqMasterPage')
  )
  // ========================== Planning ======================================
  const PlantPlanningMasterPage = lazy(
    () => import('../modules/plant-planning-master-page/PlantPlanningMasterPage')
  )
  const DailyPlanningMasterPage = lazy(
    () => import('../modules/daily-planning-master-page/DailyPlanningMasterPage')
  )
  // const EmployeeMasterPage = lazy(
  //   () => import('../modules/employee/EmployeeMasterPage')
  // )

  // =====================  My Jobcard ===========================================
  const MyJobCardMasterPage = lazy(
    () => import('../modules/my-jobcard-master-page/MyJobCardMasterPage')
  )
  const OnlineQCJobCardMasterPage = lazy(
    () => import('../modules/online-qc-jobcard-master-page/OnlineQCJobCardMasterPage')
  )
  const QC2JobCardMasterPage = lazy(
    () => import('../modules/qc2-jobcard-master-page/QC2JobCardMasterPage')
  )
  // =====================  Reports ===========================================
  const ItemBatchMasterPage = lazy(
    () => import('../modules/Reports/item-batch-master-page/ItemBatchMasterPage')
  )
  const SalesOrdersMasterPage = lazy(
    () => import('../modules/Reports/sales-orders-master-page/SalesOrdersMasterPage')
  )
  // =====================  Roll Maintenance ===========================================
  const RollMaintenanceMasterPage = lazy(
    () => import('../modules/roll-maintenance-master-pages/RollMaintenanceMasterPage')
  )
  // =====================  Stock Transfer ===========================================
  const StockTransferMasterPage = lazy(
    () => import('../modules/stock-transfer-master-pages/StockTransferMasterPage')
  )
  // ===================== Part Repairing ===========================================
  const PartRepairingMasterPage = lazy(
    () => import('../modules/part-repairing-master-pages/PartRepairingMasterPage')
  )
  // ===================== Material Inward ===========================================
  const MaterialInwardMasterPage = lazy(
    () => import('../modules/material-inward-master-page/MaterialInwardMasterPage')
  )
  // ===================== Material Outward ===========================================
  const MaterialOutwardMasterPage = lazy(
    () => import('../modules/material-outward-master-page/MaterialOutwardMasterPage')
  )

  // ========================= Cylinder =============================================
  const CylinderMasterPage = lazy(
    () => import('../modules/Cylinder-master-pages/CylinderMasterPage')
  )

  const CylinderDestructionMasterPage = lazy(
    () => import('../modules/cylinder-destruction-page/CylinderDestructionMasterPage')
  )
  // ===================== Art Work ===========================================
  const ArtWorkMasterPage = lazy(() => import('../modules/art-work-master-page/ArtWorkMasterPage'))

  const JobWorkInOutMasterPage = lazy(
    () => import('../modules/jobWork-in-out-master-page/JobWorkInOutMasterPage')
  )
  const JobWorkOutMasterPage = lazy(
    () => import('../modules/job-work-out-pages/JobWorkOutMasterPage')
  )
  //==========================Bom Return ====================================
  const BomRetunMasterPage = lazy(
    () => import('../modules/bom-return-master-page/BomRetunMastePager')
  )
  //==========================COA ====================================
  const COAMasterPage = lazy(() => import('../modules/c-o-a-master-page/COAMasterPage'))
  //==========================Customer Complain ====================================
  const CustomerComplainMasterPage = lazy(
    () => import('../modules/customer-complain-master-page/CustomerComplainMasterPage')
  )
  //==========================Customer Complain ====================================
  const VendorComplainMasterPage = lazy(
    () => import('../modules/vendor-complain-master/VendorComplainMasterPage')
  )

  //==========================Artwork ====================================
  const CylinderDestMasterPage = lazy(
    () =>
      import('../modules/artwork-master-pages/cylinder-dest-master-pages/CylinderDestMasterPage')
  )

  const CylinderMakingMasterPage = lazy(
    () =>
      import(
        '../modules/artwork-master-pages/cylinder-making-master-pages/CylinderMakingMasterPage'
      )
  )

  const SalesCylinderRecoveryMasterPage = lazy(
    () =>
      import(
        '../modules/artwork-master-pages/sales-cylinder-recv-master-pages/SalesCylinderRecoveryMasterPage'
      )
  )

  const SamplingDescriptionProductMasterPage = lazy(
    () =>
      import(
        '../modules/artwork-master-pages/sampling-des-prod-master-pages/SamplingDescriptionProductMasterPage'
      )
  )
  const CylinderReceivedMasterPage = lazy(
    () =>
      import(
        '../modules/artwork-master-pages/cylinder-received-master-pages/CylinderReceivedMasterPage'
      )
  )
  const SubGroupMasterPage = lazy(
    () =>
      import(
        '../modules/master-page/sub-group-mst-page copy/core-type-master-page/SubGroupMasterPage'
      )
  )
  const ItemCategoryMasterPage = lazy(
    () => import('../modules/master-page/item-category-master-page/ItemCategoryMasterPage')
  )

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path='/dashboard' component={DashboardWrapper} />

        {/* =========================== Master ======================================= */}
        <Route path='/master/department' component={DepartmentMasterPage} />
        <Route path='/master/user' component={UserMasterPage} />
        <Route path='/master/machine' component={MachineMasterPage} />
        <Route path='/master/part' component={PartMasterPage} />
        <Route path='/master/item-mst' component={ItemMasterMasterPage} />
        <Route path='/master/spec-part' component={PartSpecificationMasterPage} />
        <Route path='/master/process-bom' component={ProcessBOMMasterPage} />
        <Route path='/master/production-bom' component={ProductionBOMMasterPage} />
        <Route path='/master/location' component={LocationMasterPage} />
        <Route path='/master/return-reason-remark' component={ReturnReasonRemarkMaster} />
        <Route path='/master/core-type' component={CoreTypeMasterPage} />
        <Route path='/master/roll-diameter' component={RollDiameterMasterPage} />
        <Route path='/master/foil-type' component={FoilTypeMasterPage} />
        <Route path='/master/item-grp' component={ItemGroupMasterPage} />
        <Route path='/master/machine-grp' component={MachineGroupMasterPage} />
        <Route path='/master/part-sub-type' component={PartSubTypeMasterPage} />
        <Route path='/master/substrate' component={SubstrateMasterPage} />
        <Route path='/master/temper' component={TemperMasterPage} />
        <Route path='/master/type-pkg' component={TypePackingMasterPage} />
        <Route path='/master/sub-grp-mst' component={SubGroupMasterPage} />
        <Route path='/master/item-cat-mst' component={ItemCategoryMasterPage} />

        {/* ========================== Password Change ====================================== */}
        <Route path='/change/password' component={ChangePasswordPage} />

        {/* ========================== Configuration ====================================== */}
        <Route path='/config/sap-config' component={SAPConfigMasterPage} />
        <Route path='/config/email-config' component={EmailConfigMasterPage} />
        <Route path='/config/plant-config' component={PlantConfigMasterPage} />
        <Route path='/config/process-config' component={ProcessConfigMasterPage} />
        <Route path='/config/process-check-config' component={ProcessCheckConfigMasterPage} />

        {/* ========================== Machine Daily Check ====================================== */}
        <Route path='/machine-daily-check' component={MachineDailyCheckMasterPage} />
        <Route path='/machine-preventive' component={MachinePreventiveMasterPage} />
        <Route path='/machine-part-change-req' component={MachinePartChangeReqMasterPage} />
        <Route path='/cylinder-change-req' component={CylinderChangeReqMasterPage} />

        {/* ========================== Planning ====================================== */}
        <Route path='/plant-planning' component={PlantPlanningMasterPage} />
        <Route path='/daily-planning' component={DailyPlanningMasterPage} />

        {/* ========================== Testing ====================================== */}
        {/* <Route path='/employee' component={EmployeeMasterPage} /> */}

        {/* ==========================My jobcard====================================== */}
        <Route path='/my-jobcard' component={MyJobCardMasterPage} />
        <Route path='/online-qc' component={OnlineQCJobCardMasterPage} />
        <Route path='/after-qc2' component={QC2JobCardMasterPage} />

        {/* ==========================Reports====================================== */}
        <Route path='/reports/item-batch' component={ItemBatchMasterPage} />
        <Route path='/reports/sales-orders' component={SalesOrdersMasterPage} />

        {/* ==========================Artwork====================================== */}
        <Route path='/artwork/cylinder-destruction' component={CylinderDestMasterPage} />
        <Route path='/artwork/cylinder-making' component={CylinderMakingMasterPage} />
        <Route path='/artwork/sampling-done' component={SamplingDescriptionProductMasterPage} />
        <Route path='/artwork/sales-cylinder-recv' component={SalesCylinderRecoveryMasterPage} />
        <Route path='/artwork/cylinder-receive' component={CylinderReceivedMasterPage} />

        {/* ==========================Roll Maintenance====================================== */}
        <Route path='/roll-maintenance' component={RollMaintenanceMasterPage} />

        {/* ==========================Stock Transfer====================================== */}
        <Route path='/stock-transfer' component={StockTransferMasterPage} />

        {/* ==========================Stock Transfer====================================== */}
        <Route path='/part-repairing' component={PartRepairingMasterPage} />
        {/* ==========================Material Inward====================================== */}
        <Route path='/material-inward' component={MaterialInwardMasterPage} />
        {/* ==========================Material Outwoard====================================== */}
        <Route path='/material-outward' component={MaterialOutwardMasterPage} />

        {/* ==========================Cylinder====================================== */}
        <Route path='/Cylinder' component={CylinderMasterPage} />
        {/* ==========================Cylinder Destruction====================================== */}
        <Route path='/cylinder-destruction' component={CylinderDestructionMasterPage} />
        {/* ==========================Artwork====================================== */}
        <Route path='/art-work' component={ArtWorkMasterPage} />
        <Route path='/job-work-in' component={JobWorkInOutMasterPage} />
        <Route path='/job-work-out' component={JobWorkOutMasterPage} />

        {/* ==========================Bom Retun====================================== */}
        <Route path='/bom-return' component={BomRetunMasterPage} />

        {/* ==========================COA====================================== */}
        <Route path='/coa' component={COAMasterPage} />

        {/* ==========================Customer Complain====================================== */}
        <Route path='/customer-complain' component={CustomerComplainMasterPage} />
        {/* ==========================Vender Complain====================================== */}
        <Route path='/vendor-complain' component={VendorComplainMasterPage} />

        <Redirect from='/' to='/dashboard' />
        <Redirect exact from='/' to='/dashboard' />
        <Redirect to='error/404' />
      </Switch>
    </Suspense>
  )
}
